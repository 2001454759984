import React from 'react'
import { Grid, Flex, Box, Text, Heading } from '@chakra-ui/react'
import {
  FaChrome,
  FaBolt,
  FaDesktop,
  FaMobileAlt,
  FaMoneyBillAlt,
  FaLock,
} from 'react-icons/fa'

const FEATURES = [
  {
    title: 'Fast and scales to your needs',
    content:
      "We take pride in making our screenshot service API ultra fast, responsive and reliable at all times for all our users. That's why our infrastructure uses Serverless technology.",
    icon: FaBolt,
  },
  {
    title: 'Full webpage screenshots',
    content:
      "Snapiant's screenshot API allows you to easily add full page website screenshots or generate thumbnails to your site, business or blog.",
    icon: FaDesktop,
  },
  {
    title: 'Responsive screenshots',
    content:
      "Don't need webpage screenshots? Snapiant allows you to change the viewport dimensions to simulate screen sizes of all shapes and sizes. You can also supply a user agent string to screenshot mobile optimised sites and emulate mobile devices.",
    icon: FaMobileAlt,
  },
  {
    title: 'Secure',
    content:
      'All of our endpoints are HTTPS. Security and privacy are important to us.',
    icon: FaLock,
  },
  {
    title: 'We Use Google Chrome',
    content:
      'We use up to date Google Chrome instances to make pixel perfect screenshots and to ensure support for all modern browser features.',
    icon: FaChrome,
  },
  {
    title: 'Cheap',
    content:
      'Taking responsive or full webpage screenshots should not be expensive - 100 monthly shots are free - need more? Subscription Plans start at $6.50 per month.',
    icon: FaMoneyBillAlt,
  },
]

const Features = () => {
  return (
    <Flex flexDirection="column" py="12" width="100%">
      <Heading alignSelf="center" mb="12">
        Features
      </Heading>

      <Grid
        templateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']}
        columnGap="8"
        rowGap="12"
        justifyItems="center"
      >
        {FEATURES.map(feature => {
          const { title, content, icon } = feature
          return (
            <Flex
              key={feature.title}
              flexDirection="column"
              alignItems="center"
            >
              <Box as={icon} size="32px" />
              <Text mt="5" fontWeight="bold" fontSize="lg">
                {title}
              </Text>
              <Text mt="5" textAlign="center">
                {content}
              </Text>
            </Flex>
          )
        })}
      </Grid>
    </Flex>
  )
}

export { Features }
