import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Hero } from '../components/hero'
import { Features } from '../components/features'
import { Pricing } from '../components/pricing'

const HomePage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Features />
      <Pricing />
    </Layout>
  )
}

export default HomePage
