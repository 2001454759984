import React from 'react'
import {
  Grid,
  Flex,
  Text,
  Link,
  Heading,
  useColorModeValue,
  Button,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import SUBSCRIPTION_PLANS from '../utils/subscription-plans'
import { PlanCard } from '../components/plan-card'

const Pricing = () => {
  const border = useColorModeValue({}, { borderWidth: '1px' })

  return (
    <Flex
      flexDirection="column"
      maxWidth={['xl', 'xl', '4xl', '6xl']}
      px="8"
      py="12"
      width="100%"
    >
      <Heading alignSelf="center" mb="12">
        Pricing
      </Heading>

      <Grid
        gridGap="5"
        justifyContent="center"
        templateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', 'repeat(5, 1fr)']}
        templateRows="1"
      >
        {SUBSCRIPTION_PLANS.map((plan, idx) => {
          return (
            <PlanCard key={idx} plan={plan}>
              <Link as={GatsbyLink} to="/signup">
                <Button colorScheme="blue">Start for free</Button>
              </Link>
            </PlanCard>
          )
        })}
      </Grid>

      <Flex
        borderRadius="sm"
        shadow="md"
        {...border}
        alignSelf="center"
        flexDirection="column"
        maxWidth="3xl"
        mt="10"
        width="100%"
      >
        <Flex bg="gray.700" height="55px" justifyContent="center" px="4" py="4">
          <Text color="gray.50" fontWeight="bold">
            Custom Plan
          </Text>
        </Flex>
        <Text alignSelf="center" p="4">
          Our plans not enough for your screenshot needs?{' '}
          <Link
            as={GatsbyLink}
            color="blue.500"
            fontSize="lg"
            fontWeight="bold"
            ml="2"
            to="/contact"
          >
            Contact us
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

export { Pricing }
