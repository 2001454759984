import React, { useEffect, useState } from 'react'
import { Grid, Flex, Heading, Link, Box, Text, Button } from '@chakra-ui/react'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

export const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "amazon-ca.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image2: file(relativePath: { eq: "reddit.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image3: file(relativePath: { eq: "spotify.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image4: file(relativePath: { eq: "wsj.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image5: file(relativePath: { eq: "youku.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image6: file(relativePath: { eq: "youtube.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [currentImage, setCurrentImage] = useState({
    src: data.image1.childImageSharp.gatsbyImageData,
    count: 1,
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      const { count } = currentImage

      if (count === 6) {
        setCurrentImage({
          src: data.image1.childImageSharp.gatsbyImageData,
          count: 1,
        })
      } else {
        setCurrentImage({
          src: data[`image${count + 1}`].childImageSharp.gatsbyImageData,
          count: count + 1,
        })
      }
    }, 4500)

    return () => {
      clearInterval(intervalId)
    }
  }, [currentImage])

  return (
    <Grid
      columnGap="12"
      py="12"
      templateColumns={['1fr', '1fr', '1fr 2fr']}
      width="100%"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        justifySelf="center"
        maxWidth={['xs', 'lg', 'xl']}
      >
        <Heading as="h1" fontSize="5xl" fontWeight="bolder">
          Create screenshots for any webpage
        </Heading>
        <Box my="10">
          <Text fontSize={['xl']}>
            Scalable screenshot API service for your needs. Create pixel perfect
            screenshots with{' '}
            <Text as="span" fontWeight="bold">
              Google Chrome
            </Text>
            .
          </Text>
        </Box>
        <Link
          alignSelf="flex-start"
          aria-label="free signup"
          as={GatsbyLink}
          to="/signup"
        >
          <Button size="lg">Get started for free</Button>
        </Link>
      </Flex>
      <Box
        alignSelf="center"
        display={['none', 'none', 'block']}
        justifySelf="center"
        mb={{ base: 12, md: 0 }}
        shadow="xl"
        w={{ base: '80%', sm: '60%', md: '80%' }}
      >
        <GatsbyImage alt="test" image={currentImage.src} />
      </Box>
    </Grid>
  )
}
